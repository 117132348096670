import React, { useRef } from "react";
import { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { Menu, Transition } from "@headlessui/react";
import CustomLink from "@/components/Atoms/CustomLink";
import { ListRow } from "@/components/Atoms/List";
import { Item } from "@/components/Atoms/Item";
import { LangSelector } from "@/components/Molecules/LangSelector";
import { UnderlineTitle, DropdownIcon } from "@/components/Atoms/Vectors";
import { routerHandler } from "@/lib/routes/router-links-handler";
import { useRouter } from "next/router";
import { getPortfolioSlug } from "@/lib/functions";
import { FullBackgroundImage } from "../ImageCard";
import GeoWrapper from "@/components/Templates/Main/GeoConditional";

interface Props {
  items: any;
  locales: string;
  currentLocale: string;
  color: any[any];
  togglers: any[any];
  bg: any[any];
}

interface MobileProps extends Props {
  active: any;
  social: any;
  metaBg: any[any];
  online: any;
  meta: any;
  onClick: any;
  bgImage?: any;
  overwrite: boolean;
  languageList: any;
}

export function DesktopNavigationMenu({ items, color, bg }: Props) {
  const [openIndex, setOpenIndex] = useState(null);
  const menuRef = useRef<HTMLUListElement>(null);

  const handleMenuClick = (index: any) => {
    setOpenIndex(index);
  };

  const handleClickOutside = (event: any) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setOpenIndex(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const { locale } = useRouter();
  const style = {
    "--bg": bg?.hex,
    "--color": color?.hex,
  } as React.CSSProperties;

  return (
    <Menu
      ref={menuRef}
      style={style as React.CSSProperties}
      as="nav"
      className={`${styles.navigationMenu} flex flex-row items-center gap-6 brandNav`}
    >
      {({ open }) => (
        <>
          {items.map((entry: any, i: number) => {
            return entry.subItems.length > 0 ? (
              <GeoWrapper
                key={i}
                initial={entry?.countryAvailability}
                country={entry?.countries}
                list={entry?.listOfCountries}
              >
                <div key={i} className="relative">
                  <Menu.Button
                    as="li"
                    key={i}
                    onClick={() => handleMenuClick(i)}
                    className={` flex flex-row gap-2 items-center relative cursor-pointer`}
                  >
                    {entry.label}
                    {openIndex === i && (
                      <Transition
                        show={openIndex === i}
                        className={` absolute left-0 top-16 border-0 focus:outline-none`}
                        enter="transition duration-300 ease-out"
                        enterFrom="transform scale-95 opacity-0 -translate-y-4"
                        enterTo="transform scale-100 opacity-100 translate-y-4"
                        leave="transition duration-75 ease-out"
                        leaveFrom="transform scale-100 opacity-100"
                        leaveTo="transform scale-95 opacity-0"
                      >
                        <Menu.Items
                          as="ul"
                          style={style as React.CSSProperties}
                          className={` ${styles.dropdownMenu}  min-w-max`}
                        >
                          {entry.subItems.map((item: any, i: number) => (
                            <Menu.Item
                              style={style as React.CSSProperties}
                              className={` ${styles.itemNav} py-2 px-6 transition duration-300 ease-out `}
                              as="li"
                              key={i}
                            >
                              <CustomLink
                                className={"block w-full"}
                                href={`/${item.link?.slug || item.slug}`}
                              >
                                {item.label}
                              </CustomLink>
                            </Menu.Item>
                          ))}
                        </Menu.Items>
                      </Transition>
                    )}
                    <DropdownIcon
                      className={`transition duration-300 ease-out ${
                        openIndex === i ? "rotate-180" : ""
                      }`}
                      color={color?.hex}
                    />
                  </Menu.Button>
                </div>
              </GeoWrapper>
            ) : (
              <div key={i}>
                {entry.linksToProductPage ? (
                  <GeoWrapper
                    initial={entry?.countryAvailability}
                    country={entry?.countries}
                    list={entry?.listOfCountries}
                  >
                    <Menu.Item
                      className={`  transition duration-300 ease-out list-none cursor-pointer`}
                      as="li"
                      key={i}
                    >
                      <CustomLink
                        href={`${routerHandler({
                          typename: entry?.product?.product.__typename,
                          slug: [
                            entry?.portfolioConfiguration?.slug,
                            entry?.product?.product?.productTypeSpecific?.slug,
                            entry?.product?.product?.slug,
                          ],
                          locale: locale,
                        })}`}
                      >
                        {entry.label}
                      </CustomLink>
                    </Menu.Item>
                  </GeoWrapper>
                ) : (
                  <GeoWrapper
                    key={i}
                    initial={entry?.countryAvailability}
                    country={entry?.countries}
                    list={entry?.listOfCountries}
                  >
                    <Menu.Item
                      className={`  transition duration-300 ease-out list-none cursor-pointer`}
                      as="li"
                      key={i}
                    >
                      <CustomLink href={`${topMenuLink(entry, locale)}`}>
                        {entry.label}
                      </CustomLink>
                    </Menu.Item>
                  </GeoWrapper>
                )}
              </div>
            );
          })}
        </>
      )}
    </Menu>
  );
}

export function MobileNavigationMenu({
  active,
  locales,
  currentLocale,
  social,
  togglers,
  color,
  bg,
  metaBg,
  overwrite,
  items,
  online,
  meta,
  bgImage,
  languageList,
  onClick,
}: MobileProps) {
  const [width, setWidth] = useState(
    typeof window === "undefined" ? 0 : window.innerWidth
  );
  const [height, setHeight] = useState(
    typeof window === "undefined" ? 0 : window.innerHeight
  );
  const updateDimensions = () => {
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    }
  };
  const [openIndex, setOpenIndex] = useState(null);
  const menuRef = useRef<HTMLDivElement>(null);

  const handleMenuClick = (index: any) => {
    setOpenIndex(index);
  };

  const handleClickOutside = (event: any) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setOpenIndex(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const style = {
    "--bg": bg?.color.hex,
    "--color": color?.hex,
  } as React.CSSProperties;

  useEffect(
    function persistForm() {
      window.addEventListener("resize", updateDimensions);
      const body: any = document.querySelector<HTMLElement>("body");
      if (width > 1024) {
        active = false;
      } else {
        if (active == true) {
          body.classList.add("overflow-y-hidden");
          return () => {
            body.classList.remove("overflow-y-hidden");
          };
        }
      }

      return () => window.removeEventListener("resize", updateDimensions);
    },
    [updateDimensions]
  );

  return (
    <nav
      style={style as React.CSSProperties}
      className={` ${
        styles.navMenu
      } fixed left-0 top-0 pt-12 right-0 w-full   py-4 overflow-y-auto brandNav ${
        !active && "hidden"
      }`}
    >
      <FullBackgroundImage data={bgImage} />
      <div className="container flex flex-col  mx-auto p-6 pb-[90px] sm:p-12 h-full relative  ">
        <div className="flex-container">
          <Menu
            ref={menuRef}
            as="div"
            className="flex flex-col items-center font-primary cursor-pointer gap-6"
          >
            {({ open }) => (
              <>
                {items.map((entry: any, i: number) => {
                  const { locale } = useRouter();

                  return entry.subItems.length > 0 ? (
                    <GeoWrapper
                      key={i}
                      initial={entry?.countryAvailability}
                      country={entry?.countries}
                      list={entry?.listOfCountries}
                    >
                      <div className="flex flex-col gap-2 items-center ">
                        <Menu.Button
                          onClick={() => handleMenuClick(i)}
                          as="li"
                          className={` flex flex-row  cursor-pointer ease-in duration-150 items-center gap-2 `}
                        >
                          {entry.label}
                          <DropdownIcon
                            className={`transition duration-300 ease-out ${
                              openIndex === i ? "rotate-180" : ""
                            }`}
                            color={color?.hex}
                          />
                        </Menu.Button>
                        {openIndex === i && (
                          <Transition
                            show={openIndex === i}
                            className={` left-0 top-16 border-0 focus:outline-none`}
                            enter="transition duration-300 ease-out"
                            enterFrom="transform scale-95 opacity-0 -translate-y-4"
                            enterTo="transform scale-100 opacity-100 translate-y-0"
                            leave="transition duration-75 ease-out"
                            leaveFrom="transform scale-100 opacity-100"
                            leaveTo="transform scale-95 opacity-0"
                          >
                            <Menu.Items
                              as="ul"
                              className={` ${styles.dropdownMenu}  min-w-max`}
                            >
                              {entry.subItems.map((item: any, i: number) => (
                                <Menu.Item
                                  onClick={onClick}
                                  className={`  py-2 px-6 transition duration-300 ease-out text-center cursor-pointer`}
                                  as="li"
                                  key={i}
                                >
                                  <CustomLink
                                    className={"block w-full"}
                                    href={`/${item.link?.slug || item.slug}`}
                                  >
                                    {item.label}
                                  </CustomLink>
                                </Menu.Item>
                              ))}
                            </Menu.Items>
                          </Transition>
                        )}
                      </div>
                    </GeoWrapper>
                  ) : (
                    <GeoWrapper
                      key={i}
                      initial={entry?.countryAvailability}
                      country={entry?.countries}
                      list={entry?.listOfCountries}
                    >
                      <Item color={color} data={entry} style={""} />
                    </GeoWrapper>
                  );
                })}
              </>
            )}
          </Menu>
          {online &&
            online.map((entry: any, i: number) => (
              <div key={i} className=" py-24 flex flex-col items-center gap-2">
                <a
                  className="cursor-pointer"
                  rel="noreferrer"
                  target={entry.externalLink ? "_blank " : "_self"}
                  href={entry.externalUrl}
                >
                  {entry.label}
                </a>
                <UnderlineTitle color="white" className="" />
              </div>
            ))}
        </div>

        <div className={` flex-container  p-4`}>
          {meta.length > 0 && (
            <div className="privacyContainer text-center text-sm mt-12">
              <ListRow tag={""} style={""}>
                {meta.map((entry: any, i: number) => (
                  <Item color={color} style="noWrap" key={i} data={entry} />
                ))}
              </ListRow>
            </div>
          )}

          {social.hasSocial && (
            <div className="flex xs:flex-col xs:justify-center xs:gap-y-6 flex-row justify-around mt-4 mb-6 ">
              <ListRow style="fixedRow" tag={""}>
                {social.socialLinks.map((entry: any, i: number) => {
                  const style = {
                    "--svg": `url(${entry.image?.url})`,
                  };

                  return entry.swapImage ? (
                    <CustomLink
                      target="_blank"
                      key={i}
                      href={`${entry.externalUrl}`}
                      external={"true"}
                    >
                      <div
                        style={style as React.CSSProperties}
                        className={
                          overwrite
                            ? styles.socialIcon
                            : styles.socialIconDefault
                        }
                      ></div>
                    </CustomLink>
                  ) : (
                    <Item
                      style="noWrap"
                      key={i}
                      data={entry}
                      color={undefined}
                    />
                  );
                })}
              </ListRow>
            </div>
          )}
          {languageList.length > 1 && (
            <div className="mt-4 lg:mt-12 ">
              <ListRow style="fixedRow" tag="">
                <LangSelector
                  color={color}
                  locales={locales}
                  currentLocale={currentLocale}
                />
              </ListRow>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
}

export function topMenuLink(entry: any, locale: string | undefined) {
  if (entry?.link?.__typename === "BrandsProductRecord") {
    const portfolioSlug = getPortfolioSlug(entry.link, locale);
    return routerHandler({
      typename: entry?.link?.__typename,
      slug: [portfolioSlug],
      locale: locale,
    });
  } else {
    return `/${entry.link?.slug || entry.slug}`;
  }
}
