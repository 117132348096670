import { useRouter } from "next/router";
import { useState, useEffect } from "react";
import dynamic from "next/dynamic";

const LangSelectItemLinks = dynamic(() =>
  import("@/components/Atoms/Item").then((c) => c.LangSelectItemLinks)
);

export function LangSelector({ locales, currentLocale, color }) {
  let router = useRouter();
  const [lang, setLang] = useState(router.locale);
  const [languageMapper, setLanguageMapper] = useState([]);
  const siteName = process.env.NEXT_PUBLIC_WEBSITE_NAME;

  let languagesTranslate = {
    pt: "PT",
    es: "ES",
    en: "EN",
  };

  // This needs to be fixed!
  /**
   * TODO:
   * Remove this and find a way to allow languages
   */
  if (siteName === "Legado") {
    languagesTranslate = {
      pt: "PT",
      es: "ES",
      en: "EN",
    };
  }

  if (siteName === "Silk & Spice 2023") {
    languagesTranslate = {
      en: "EN",
      pt: "PT",
    };
  }

  if (siteName === "solisto") {
    languagesTranslate = {
      en: "EN",
    };
  }

  let paths = [];
  useEffect(() => {
    let changeLg = router.components;
    let pathName = router.pathname;
    let pathsSplit = pathName.replace(/\[|\]/gm, "").split("/"); // replace [] and separate
    let moreThenOne = pathsSplit.length > 2 ? true : false;

    let type = pathsSplit[pathsSplit.length - 1]; // get last element
    type = type !== "" ? type : "/";

    const data = [changeLg].map((c) => c[router.route])[0];

    let initialData = data?.props?.pageProps?.data;
    const portfolio = initialData?.brandsPortfolio?._allSlugLocales;
    const isProductPage = portfolio && initialData.h3 !== undefined;

    let currentSlugs =
      portfolio && !isProductPage ? portfolio : initialData?._allSlugLocales;

    let firstLevelSlugs = moreThenOne ? initialData?._allSlugLocales : false;
    if (type !== "/") {
      currentSlugs?.map((slug) => {
        let firstPath = "";
        firstPath = moreThenOne
          ? firstLevelSlugs.filter((f) => f.locale === slug.locale)?.[0]
              ?.value + "/"
          : "";

        firstPath = isProductPage
          ? portfolio.filter((f) => f.locale === slug.locale)?.[0]?.value + "/"
          : "";

        paths.push({ locale: slug.locale, path: "/" + firstPath + slug.value });
      });
    }
    setLanguageMapper(paths);
  }, [router]);

  return (
    <>
      <LangSelectItemLinks
        color={color}
        languageMapper={languageMapper}
        currentLocale={currentLocale}
        languagesTranslate={languagesTranslate}
        router={router}
      />
    </>
  );
}
