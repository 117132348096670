import CustomLink from "@/components/Atoms/CustomLink";
import { topMenuLink } from "@/components/Molecules/NavigationMenu";
import { useRouter } from "next/router";
import styles from "./index.module.scss";
import Link from "next/link";
import { routerHandler } from "@/lib/routes/router-links-handler";

interface Props {
  data: any;
  style: string;
  rule?: string;
  onClick?: any;
  color: any[any];
  className?: string;
}

interface LangProps extends Props {
  currentLocale: string;
  languageMapper?: any;
  languagesTranslate?: any;
  router?: any;
}

export function Item({ data, rule, onClick, color, className }: Props) {
  const { locale } = useRouter();
  return (
    <li
      style={{ color: color?.hex }}
      onClick={onClick}
      className={`flex flex-row  cursor-pointer ease-in duration-150   ${className} `}
    >
      {rule !== "noLink" ? (
        <>
          {" "}
          {data.linksToProductPage ? (
            <CustomLink
              href={`${routerHandler({
                typename: data?.product?.product.__typename,
                slug: [
                  data?.portfolioConfiguration?.slug,
                  data?.product?.product.productTypeSpecific?.slug,
                  data?.product?.product.slug,
                ],
                locale: locale,
              })}`}
            >
              {data.label}
            </CustomLink>
          ) : (
            <CustomLink href={topMenuLink(data, locale)}>
              {data.label}
            </CustomLink>
          )}{" "}
        </>
      ) : (
        <> {data.label}</>
      )}
    </li>
  );
}
export function LangSelectItem({
  data,
  onClick,
  currentLocale,
  color,
}: LangProps) {
  return (
    <li
      style={{ color: color?.hex }}
      className={`${
        data.locale == currentLocale && " "
      }flex flex-row  ease-in duration-150 cursor-pointer`}
    >
      <CustomLink onClick={onClick} href="/">
        {data.locale}
      </CustomLink>
    </li>
  );
}

export function LangSelectItemLinks({
  languageMapper,
  currentLocale,
  languagesTranslate,
  router,
  color,
}: LangProps) {
  const style = { "--color": color?.hex } as React.CSSProperties;
  const localesToShow = process.env.NEXT_PUBLIC_LOCALES_TO_SHOW ?? "";

  const changeLanguageUsingLinkRefreshingPage = (e: any) => {
    e.preventDefault();
    const locale = e.target.href;
    window.location.href = locale;
  };

  if (languageMapper.length > 1) {
    return languageMapper.map((path: any, i: number) => {
      if (
        (languagesTranslate[path.locale] &&
          localesToShow?.indexOf(
            languagesTranslate[path.locale]?.toLowerCase()
          ) > -1) ||
        (languagesTranslate[path.locale] && localesToShow === "")
      ) {
        return (
          <li
            key={i}
            style={style as React.CSSProperties}
            className={` ${styles.list} ${
              path.locale == currentLocale && " font-bold p-1 border-b-2 "
            }flex flex-row  ease-in duration-150  uppercase text-sm cursor-pointer brandNav`}
          >
            <a
              href={`/${path.locale}${path.path}`}
              onClick={changeLanguageUsingLinkRefreshingPage}
            >
              {languagesTranslate[path.locale]}
            </a>
          </li>
        );
      } else {
        return null;
      }
    });
  } else {
    if (router.locales.length > 1) {
      return router.locales.map((locale: any, i: number) => {
        if (
          (languagesTranslate[locale] &&
            localesToShow?.indexOf(languagesTranslate[locale]?.toLowerCase()) >
              -1) ||
          (languagesTranslate[locale] && localesToShow === "")
        ) {
          return (
            <li
              style={style as React.CSSProperties}
              key={i}
              className={`${styles.list} ${
                locale == currentLocale && " font-bold p-1 border-b-2 "
              } flex flex-row  ease-in duration-150  uppercase text-sm cursor-pointer brandNav`}
            >
              <a
                href={`/${locale}`}
                onClick={changeLanguageUsingLinkRefreshingPage}
              >
                {languagesTranslate[locale]}
              </a>
            </li>
          );
        } else {
          return null;
        }
      });
    } else {
      return null;
    }
  }
}
